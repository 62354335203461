<!-- 打印准考证与点名表 -->
<template>
  <div class="AdmissionTicket">
    <!-- 查询表单 -->
    <el-form ref="form" :model="form" inline class="form" label-width="120px">
      <el-form-item label="查询日期">
        <el-date-picker
          v-model="form.queryDate"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="考试场次">
        <el-select
          v-model="form.testSite"
          filterable
          placeholder="请输入并选择考试场次"
          :clearable="true"
        >
          <el-option
            v-for="item in form.testSiteChoose"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="考试大类">
        <el-select
          v-model="form.exam"
          filterable
          placeholder="请选择考试大类"
          :clearable="true"
        >
          <el-option
            v-for="item in form.examOpt"
            :key="item.id"
            :label="item.exam_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="准考证考生">
        <el-input
          v-model="form.examinee"
          placeholder="请输入准考证考生"
        ></el-input>
      </el-form-item>
      <el-form-item label="指定机构">
        <el-select
          v-model="form.appoint"
          filterable
          placeholder="请输入并选择指定机构"
          :clearable="true"
        >
          <el-option
            v-for="item in form.appointChoose"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="" style="margin-right: 10px;width: 300px;text-align: right;">
        <el-button
          size="small"
          style="background: #18BC9C;color: #FFFFFF;"
          @click="query"
        >
          查询
        </el-button>
        <el-button
          size="small"
          style="background: #FAFAFA;color: #666666;"
          @click="Reset"
        >
          重置
        </el-button>
      </div>
    </el-form>
    <!-- 按钮 -->
    <div class="but" style="margin-left: 25px;">
      <el-button
        size="small"
        icon="el-icon-refresh"
        style="background-color: #2C3E50; border-radius: 3px;"
        @click="refresh"
      >
        <!-- 刷新 -->
      </el-button>
      <button class="derive" @click="derive">导出到Excel</button>
      <button class="print" @click="print">打印预览</button>
      <el-select v-model="form.print" placeholder="请选择" class="printChoice">
        <el-option
          v-for="item in form.printChoice"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <button class="field" @click="nameList">本场名单</button>
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      @selection-change="checkAll"
      style="width:97.9%;flex: 1;"
      height="650"
      @sort-change="abc"
    >
      <!-- 复选按钮 -->
      <el-table-column type="selection" align="center" width="50" fixed="left">
      </el-table-column>
      <el-table-column type="index" label="序号" align="center" width="80">
      </el-table-column>
      <el-table-column
        prop="print_num"
        sortable="custom"
        label="打印次数"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="org_name"
        sortable="custom"
        label="提供机构"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        sortable="custom"
        label="单据状态"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.status == 0 || scope.row.status == ''"></span>
          <span v-else-if="scope.row.status == 1">草稿</span>
          <span v-else-if="scope.row.status == 2">报考</span>
          <span v-else-if="scope.row.status == 3">准考</span>
          <span v-else-if="scope.row.status == 4">考试OK</span>
          <span v-else-if="scope.row.status == 5">取消</span>
          <span v-else-if="scope.row.status == 6">退款取消</span>
          <span v-else-if="scope.row.status == 7">延期</span>
          <span v-else-if="scope.row.status == 8">缺考</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="is_deputy"
        sortable="custom"
        label="是否副证"
        align="center"
        width="120"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.is_deputy == 0">否</span>
          <span v-else-if="scope.row.is_deputy == 1">是</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="enroll_number"
        sortable="custom"
        label="报名单号"
        align="center"
        width="160"
      >
      </el-table-column>
      <el-table-column
        prop="certificate_no"
        sortable="custom"
        label="准考证号"
        align="center"
        width="240"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        sortable="custom"
        label="学员姓名"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="english_name"
        sortable="custom"
        label="拼音名"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="idcard"
        sortable="custom"
        label="身份证"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="head_image"
        label="相片"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <img
            :src="scope.row.head_image"
            style="width:15px;height:20px;margin-top: 5px;"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="course"
        sortable="custom"
        label="考试科目"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_level"
        sortable="custom"
        label="等级"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="original_level"
        sortable="custom"
        label="原等级"
        align="center"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="site_name"
        sortable="custom"
        label="考试场地"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column
        prop="exam2_date"
        sortable="custom"
        label="考试日期"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_startdate"
        sortable="custom"
        label="开始时间"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_enddate"
        sortable="custom"
        label="结束时间"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="exam_duration"
        sortable="custom"
        label="时长(hr)"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="price1"
        sortable="custom"
        label="应收金额"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="price2"
        sortable="custom"
        label="实收金额"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="work_price"
        sortable="custom"
        label="作品费"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="exam_date"
        sortable="custom"
        label="下单日期"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="order_name"
        sortable="custom"
        label="下单人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="address"
        sortable="custom"
        label="考场地址"
        align="center"
        width="300"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        prop="add_name"
        sortable="custom"
        label="录入人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="add_time"
        sortable="custom"
        label="录入时间"
        align="center"
        width="150"
      >
      </el-table-column>
      <el-table-column
        prop="update_name"
        sortable="custom"
        label="修改人"
        align="center"
        width="120"
      >
      </el-table-column>
      <el-table-column
        prop="update_time"
        sortable="custom"
        label="最近修改时间"
        align="center"
        width="200"
      >
      </el-table-column>
      <el-table-column label="操作" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-view"
            size="mini"
            style="background: #18BC9C;color:#FFFFFF;"
            @click="details(scope.$index, scope.row)"
          >
            资料
          </el-button>
          <!-- <el-button  icon="el-icon-printer" size="mini" style="background:#FF7300;color:#FFFFFF;" >
                        打印
                    </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="" style="display: flex; justify-content: space-between;">
      <!-- <div class="" style="margin-left: 22px;">
                <div class="txtbox">
                    提供机构 <input type="text" class="itp" :value="provide" disabled="disabled"/>
                </div>
                <div class="txtbox">
                    场地地址 <input type="text" class="itp" :value="address" disabled="disabled" style="width: 400px;"/>
                </div>
            </div> -->
      <!--分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 50, 100, 200, 500, 1000]"
        :page-size="10"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 打印预览弹窗 -->
    <el-dialog
      width="950px"
      :visible.sync="dialog"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 准考证 -->
      <div id="printAdmission" v-if="form.print == 1">
        <div class="" v-for="(item, index) in admissions" :key="index">
          <!-- 准考证表格 -->
          <table class="table_one" border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td rowspan="6" class="td_1"><img :src="item.head_image" /></td>
              <td colspan="8" class="td_2">
                中国美术学院社会美术水平考级中心准考证
              </td>
            </tr>
            <tr>
              <!-- <td colspan="2" class="td_4"> 姓名 : {{ item.name }}</td> -->
              <td colspan="2" class="td_6">姓名 : {{ item.name }}</td>
              <td colspan="3">身份证 : {{ item.idcard }}</td>
              <td colspan="3" class="td_3">{{ item.english_name }}</td>
            </tr>
            <tr>
              <!-- <td colspan="2" class="td_4"> 国籍 : {{ item.nation2 }}</td> -->
              <td colspan="2" class="td_6">国籍 : {{ item.nation2 }}</td>
              <td colspan="2" class="td_8" width="180px">
                准考证 : {{ item.certificate_no }}
              </td>
              <td colspan="2" width="130px">民族 : {{ item.nation }}</td>
              <td colspan="2">性别 : {{ item.gender }}</td>
            </tr>
            <tr>
              <td colspan="3">承办机构 : 深圳书画艺术学院</td>
              <td colspan="3">专业 : {{ item.course }}</td>
              <td colspan="2">级别 : {{ item.exam_level }}</td>
            </tr>
            <tr>
              <td colspan="6">考场地址 : {{ item.address }}</td>
              <td colspan="2">联系电话:{{ item.phone }}</td>
            </tr>
            <tr>
              <td class="td_5">机构名称 :</td>
              <td colspan="2" class="td_6">{{ item.org_name }}</td>
              <td class="td_7">考试时间 :</td>
              <td align="center" style="padding: 0px;">
                {{ item.exam2_date }}<br />{{ item.exam_time }}
              </td>
              <td colspan="3">考场 : {{ item.site_name }}</td>
            </tr>
          </table>

          <div class="bottom">
            <!-- 考生须知 -->
            <h3>考生须知 :</h3>
            <div class="notice">
              <p>
                1、考卷由中国美术学院专家委员会统一命题，在中国美院统一评卷，评审后统一发证。
              </p>
              <p>
                2、考生拿到准考证后要仔细核对，如有疑问请与学院考级办联系，并上交错误准考证
              </p>
              <p>
                3、考生须持准考证并按照准考证上规定的时间、地址和考场参加考试，遵守考试纪律。
                <br /><span style="padding-left: 22px;"
                  >无准考证或准考证与本人身份不符者不得进入考场。</span
                >
              </p>
              <p>
                4、考生需将准考证副联贴在试卷背面右下角,并将上级别证书复印件贴在作品背后,废卷不得带出考场
              </p>
              <p>
                5.
                考生根据考纲带好考试用写画工具。考试用纸由考级办统一配发。咨询电话：88830206
              </p>
              <!-- 印章定位 -->
              <div class="seal" v-if="item.is_arrange == 1">
                <img :src="seal" />
              </div>
            </div>
          </div>
          <!-- 删除线 -->
          <div class="xian"></div>
          <!-- 副联表 -->
          <table class="table_two" border="1" cellspacing="0" cellpadding="0">
            <tr>
              <td rowspan="4" class="td_1"><img :src="item.head_image" /></td>
              <td colspan="4" class="td_2">
                中国美术学院社会美术水平考级中心准考证(副联)
                <span>[副证:{{ item.is_deputy }}]</span>
              </td>
            </tr>
            <tr>
              <td class="td_10">姓名 : {{ item.name }}</td>
              <td>报考专业 : {{ item.course }}</td>
              <td>身份证 : {{ item.idcard }}</td>
              <td rowspan="3" class="QRcode">
                <img :src="item.qrcode" />
              </td>
            </tr>
            <tr>
              <td class="td_10">性别 : {{ item.gender }}</td>
              <td>现报级别 : {{ item.exam_level }}</td>
              <td>准考证号 : {{ item.certificate_no }}</td>
            </tr>
            <tr>
              <td colspan="2">机构名称 : {{ item.org_name }}</td>
              <td>承办机构 : 深圳书画艺术学院</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- 点名表 v-if="form.print==2" -->
      <div id="printAdmission" v-if="form.print == 2">
        <!-- 点名表内容 -->
        <div class="rollcall" v-for="(item, index) in rollcall" :key="index">
          <!-- 头部 -->
          <h2>{{ item.year }}年 中国美术学院社会美术水平考试 点名表</h2>
          <div class="top center">
            <div class="top_1">考场名称 : {{ item.address }}</div>
            <div class="top_2">
              共 {{ item.sum_page }} 页-第 {{ index + 1 }} 页
            </div>
          </div>
          <!-- 中间 -->
          <div class="center">
            <div class="c_1">机构 : {{ item.org_name }}</div>
            <div class="c_2">
              考试时间 :
              <input
                :value="item.exam2_date"
                style="width: 200px;border: none;outline: none;"
              />
            </div>
          </div>
          <!-- 下面 -->
          <div class="below center">
            <div class="c_1">监考老师 : {{ item.teacher }}</div>
            <div
              class="c_2"
              style="padding-right:90px;font-size:14px"
              v-if="item.exam_startdate != ''"
            >
              {{ item.exam_startdate }} ~ {{ item.exam_enddate }}
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            :data="item.list"
            border
            :header-cell-style="{ background: '#ffff7f', color: '#333' }"
          >
            <el-table-column
              prop="number"
              label="序号"
              align="center"
              width="40"
            >
            </el-table-column>
            <el-table-column
              prop="certificate_no"
              label="准考证号"
              align="center"
              width="160"
            >
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center" width="85">
            </el-table-column>
            <el-table-column
              prop="english_name"
              label="拼音名"
              align="center"
              width="130"
            >
            </el-table-column>
            <el-table-column
              prop="gender"
              label="性别"
              align="center"
              width="43"
            >
            </el-table-column>
            <el-table-column
              prop="nation"
              label="民族"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="birthdate"
              label="出生日期"
              align="center"
              width="90"
            >
            </el-table-column>
            <el-table-column
              prop="course"
              label="报考专业"
              align="center"
              width="70"
            >
            </el-table-column>
            <el-table-column
              prop="exam_level"
              label="报考级别"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              align="center"
              width="100"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="record">
          <div class="">
            <span>考生数量：</span><input type="" :value="sum" disabled />
          </div>
          <div class="">
            <span>实考人数：</span><input type="" value="" disabled />
          </div>
          <div class="">
            <span>缺考人数：</span><input type="" value="" disabled />
          </div>
          <div class="">
            <span>监考签名：</span><input type="" value="" disabled />
          </div>
        </div>
        <p style="color: #333;padding:15px 0px;font-size: 15px;">
          <span style="font-weight: bold;">
            备注说明 :
          </span>
          <input
            type="text"
            value=""
            style="width: 91%;border: none;border-bottom: 1px solid #333;outline: none;background: #fff;"
            disabled
          />
        </p>
      </div>

      <!-- 考证副联 -->
      <div id="printAdmission" v-if="form.print == 3">
        <div style="margin-bottom: 71px;" v-for="(item, index) in vice">
          <table
            class="table_three"
            border="1"
            cellspacing="0"
            cellpadding="0"
            :key="index"
          >
            <tr>
              <td rowspan="4" class="td_1"><img :src="item.head_image" /></td>
              <td colspan="4" class="td_2">
                中国美术学院社会美术水平考级中心准考证(副联)
                <span>[副证:{{ item.is_deputy }}]</span>
              </td>
            </tr>
            <tr>
              <td class="td_10">姓名: {{ item.name }}</td>
              <td>报考专业: {{ item.course }}</td>
              <td>身份证: {{ item.idcard }}</td>
              <td rowspan="3" class="QRcode">
                <img :src="item.qrcode" />
                <!-- QRcode -->
              </td>
            </tr>
            <tr>
              <td class="td_10">性别: {{ item.gender }}</td>
              <td>现报级别: {{ item.exam_level }}</td>
              <td>准考证号: {{ item.certificate_no }}</td>
            </tr>
            <tr>
              <td colspan="2">考点名称: {{ item.org_name }}</td>
              <td>承办机构: 深圳市考级办公室</td>
            </tr>
          </table>

          <!-- <div class="xian1"></div> -->
        </div>
      </div>

      <!-- 国美副联 -->
      <div id="printAdmission" v-if="form.print == 4">
        <!-- style="margin-bottom: 46px;font-size: 19px; font-family: 'SimSun', 'Times New Roman', Times, serif;" -->
        <div
          style="margin-bottom: 58px;font-size: 19px;font-family: 'SimSun', 'Times New Roman', Times, serif;"
          v-for="(item, index) in guomei"
          :key="index"
        >
          <table class="table_three" border="1" cellspacing="0" cellpadding="0">
            <!-- :key="index" -->
            <tr>
              <td colspan="4" class="td_2" style="font-size: 23px;">
                中国美术学院社会美术水平考级中心准考证副联
              </td>
            </tr>
            <tr>
              <td style="width: 160px;">姓名: {{ item.name }}</td>
              <td>身份证: {{ item.idcard }}</td>
              <td style="width: 190px;">
                专业级别: {{ item.course }}{{ item.exam_level }}
              </td>
            </tr>
            <tr>
              <td style="width: 160px;">
                机构名称: 深圳市德艺智文化艺术传媒有限公司
              </td>
              <td class="barcode">
                <img
                  :src="
                    'https://artxt.szart.cn/api/public/index.php/addons/barcode/index/build?text=' +
                      item.enroll_number
                  "
                />
                <!-- QRcode -->
                <div class="codenum" v-if="item.danhao">
                  <!-- {{ item.danhao }} -->
                  <div
                    v-for="(nitem, nindex) in item.danhao.split('')"
                    :key="nindex"
                  >
                    {{ nitem }}
                  </div>
                </div>
              </td>
              <td class="QRcode guo" style="width: 190px;margin: 5px auto;">
                <img :src="item.qrcode" />
                <!-- QRcode -->
              </td>
            </tr>
          </table>

          <!-- <div class="xian1" style="margin: 20px 0;"></div> -->
        </div>
      </div>

      <!-- 底部打印按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <!-- <el-button style="background: #18BC9C;color: #fff;" 
                v-print="form.print==1?'#printAdmission':form.print==2?'#printRollcall':'#printAssociate'">
                    打 印
                </el-button> -->
        <el-button
          style="background: #18BC9C;color: #fff;"
          v-print="'#printAdmission'"
          @click="ticke"
        >
          打 印
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        queryDate: "", //查询日期
        testSite: "", //考试场次
        testSiteChoose: [], //考试场次选项
        testDate: ["00:00:00", "00:00:00"], //考试时间
        examinee: "", //准考证考生
        appoint: "", //指定机构
        appointChoose: [], //指定机构选项
        print: 1, //打印类型
        printChoice: [
          {
            label: "准考证",
            value: 1,
          },
          {
            label: "点名表",
            value: 2,
          },
          {
            label: "考证副联",
            value: 3,
          },
          {
            label: "国美副联",
            value: 4,
          },
        ], //打印类型选择
        exam: "", //考试大类
        examOpt: [], //考试大类选项
      },
      tableData: [], //表格数据
      currentPage: 1,
      currentLimit: 20,
      total: 0,
      provide: "", //提供机构
      address: "", //场地地址
      ids: [], //用于接收id
      id: "",
      dialog: false, //判断打印预览开关
      seal: require("../../assets/zhang_1.png"), //印章
      type: 1, //判断是否有印章
      admission: {
        //准考证信息
        head_img: "", //头像
        name: "", //姓名
        idcard: "", //身份证
        pinyin: "", //拼音名
        nationality: "", //国籍
        prove: "", //准考证
        nation: "", //民族
        sex: "", //性别
        org_name: "深圳书画艺术学院", //承办机构
        major: "", //专业
        level: "", //级别
        address: "", //考场地址
        phone: "", //联系电话
        test_site: "", //考点
        test_time: "", //考试时间
        test_room: "", //考场
      },
      associate: {
        //副联表数据
        head_img: "", //头像
        yesNo: "", //是否副证
        name: "", //姓名
        idcard: "", //身份证
        sex: "", //性别
        prove: "", //准考证
        major: "", //报考专业
        level: "", //原有级别
        grade: "", //现报级别
        test_name: "", //考点名称
        org_name: "深圳书画艺术学院", //承办机构
        QRcode: "", //二维码
      },

      // 准考证新格式数据
      admissions: [
        {
          head_img: "", //头像
          name: "", //姓名
          idcard: "", //身份证
          pinyin: "", //拼音名
          nationality: "", //国籍
          prove: "", //准考证
          nation: "", //民族
          sex: "", //性别
          org_name: "深圳书画艺术学院", //承办机构
          major: "", //专业
          level: "", //级别
          address: "", //考场地址
          phone: "", //联系电话
          test_site: "", //考点
          test_time: "", //考试时间
          test_room: "", //考场
          associate: [
            {
              head_img: "", //头像
              yesNo: "", //是否副证
              name: "", //姓名
              idcard: "", //身份证
              sex: "", //性别
              prove: "", //准考证
              major: "", //报考专业
              level: "", //原有级别
              grade: "", //现报级别
              test_name: "", //考点名称
              org_name: "深圳书画艺术学院", //承办机构
              QRcode: "", //二维码
            },
          ],
        },
      ],

      rollcall: [
        {
          year: "", //年
          room_name: "", //考场名称
          count: "", //总页数
          // page:"1",//第几页
          subject_grade: "", //科目与等级
          test_time: "", //考试时间
          teacher: "", //监考老师
          list: [
            //     {
            //     date: ' MH3.21070011-XTDZ',
            //     name: '王小虎',
            //     name2:"wang xiao hu",
            //     address: '上海市-普陀区-金沙江',
            //     sex:"男",
            //     nation:"汉族",//民族
            //     birthday:"2001-12-12",
            //     major:"漫画漫画",//专业
            //     level:"三级",//级别
            // },
          ],
        },
      ], //点名表数据
      sum: "", //考生数量
      actual_num: "", //实考人数
      lack_num: "", //缺考人数
      explain: "", //备注说明
      vice: [
        //     {//考证副联表数据
        //     head_img: require('../../assets/shangping.jpg'), //头像
        //     yesNo:"否",//是否副证
        //     name:"测试胡",//姓名
        //     sex:"男",//性别
        //     prove:"MH3.21070016-XTD",//准考证
        //     major:"漫画",//报考专业
        //     level:"三级",//原有级别
        //     grade:"四级",//现报级别
        //     test_name:"深圳书画艺术学院",//考点名称
        //     org_name:"深圳市考级办公室",//承办机构
        // },
      ],
      guomei: [],
      ticket: "",
    };
  },
  created() {
    this.getList();

    // 考试场次
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      // console.log(res,'考试场次')
      this.form.testSiteChoose = res.data.list;
    });

    // 机构数据
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,'机构数据')
      this.form.appointChoose = res.data.list;
    });
    // 考试大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      // console.log(res,"考试大类")
      if (res.code == 1) {
        this.form.examOpt = res.data.list;
      }
    });
  },
  activated() {
    this.getList();
  },
  computed: {
    //计算属性
  },
  methods: {
    // 列表
    getList() {
      this.$request({
        url: "/api/Admission/list",
        method: "POST",
        data: {
          limit: this.currentLimit,
          page: this.currentPage,
          org_id: this.form.appoint,
          name: this.form.examinee,
          apply_category: this.form.testSite,
          dateQuery: this.form.queryDate,
          apply_category: this.form.exam,
          sort: this.arr,
        },
      }).then((res) => {
        // console.log(res)
        this.tableData = res.data.list;
        this.total = res.data.count;
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.currentLimit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList();
    },
    query() {
      //查询
      this.currentPage = 1;
      this.getList();
    },
    Reset() {
      //重置
      (this.form.queryDate = ""), //查询日期
        // this.form.testSite="",//考试场次
        // this.form.testDate=''//考试时间
        (this.form.exam = "");
      this.form.examinee = ""; //准考证考生
      this.form.appoint = ""; //指定机构
      this.getList();
    },
    // 刷新
    refresh() {
      this.$router.push({
        path: "/home/newpage", //空页面路由
        query: {
          path: this.$route.path,
        },
      });
    },
    details(index, row) {
      this.$router.push({
        path: "studentDetails", //资料页面
        query: {
          id: row.id,
        },
      });
    },
    nameList() {
      this.$router.push({
        path: "nameList", //本场名单
        query: {
          id: this.id,
        },
      });
    },
    checkAll(selection) {
      //获取批量id
      // console.log(selection)
      this.ids = [];
      this.id = "";
      selection.forEach((item) => {
        this.ids.push(item.id);
        this.id = item.id;
      });
      // console.log(this.ids)
    },
    derive() {
      //导出
      location.href = `https://artxt.szart.cn/api/public/index.php/api/Admission/export?
                    page=${this.currentPage}&limit=${this.currentLimit}&dateQuery=${this.form.queryDate}
                    &name=${this.form.examinee} `;
    },

    print() {
      //打印预览
      this.dialog = true;
      // console.log(this.form.print)
      if (this.form.print == 1) {
        //准考证
        this.$request({
          url: "/api/Admission/Admission",
          method: "POST",
          data: {
            id: this.ids,
            // this.ids
          },
        }).then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.admissions = res.data;
            if (this.admissions.length == 1) {
              this.ticket = `${this.admissions[0].org_name}准考证B${this.admissions[0].name}${this.admissions[0].course}${this.admissions[0].exam_level}`;
            }
            // let admission = this.admission
            // let data = res.data
            // admission.head_img = data.head_image //头像
            // admission.name = data.name //姓名
            // admission.idcard = data.idcard //身份证
            // admission.pinyin = data.english_name //拼音名
            // admission.nationality = data.nation2 //国籍
            // admission.prove = data.certificate_no //准考证
            // admission.nation = data.nation //民族
            // admission.sex = data.gender //性别
            // admission.major = data.course //专业
            // admission.level = data.exam_level //级别
            // admission.address = data.address //考场地址
            // admission.phone = data.phone //联系电话
            // admission.test_site = data.org_name //考点
            // admission.test_time = data.exam2_date //考试时间
            // admission.test_room = data.site_name //考场
            // this.type=data.is_arrange//是否有印章
            // // 副联表
            // let associate = this.associate
            // associate.head_img = data.head_image //头像
            // associate.yesNo = data.is_deputy //是否副证
            // associate.name = data.name //姓名
            // associate.idcard = data.idcard //姓名
            // associate.sex = data.gender //性别
            // associate.prove = data.certificate_no //准考证
            // associate.major = data.course //报考专业
            // associate.level = data.original_level //原有级别
            // associate.grade = data.exam_level //现报级别
            // associate.test_name = data.org_name //考点名称
            // admission.QRcode=data.qrcode//二维码
          } else {
            this.dialog = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else if (this.form.print == 2) {
        //点名表
        console.log("点名表");
        this.$request({
          url: "/api/Admission/callroll",
          method: "POST",
          data: {
            id: this.id,
            // id:92917
          },
        }).then((res) => {
          console.log(res);
          if (res.code == 1) {
            this.rollcall = res.data;
            this.sum = res.data[0].count;
          } else {
            this.dialog = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else if (this.form.print == 3) {
        //考证副联
        this.$request({
          url: "/api/Admission/Associate",
          method: "POST",
          data: {
            ids: this.ids,
          },
        }).then((res) => {
          // console.log(res,'考证副联')
          if (res.code == 1) {
            this.vice = res.data.list;
          } else {
            this.dialog = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else if (this.form.print == 4) {
        // 国美副联
        this.$request({
          url: "/api/Admission/guomei",
          method: "POST",
          data: {
            ids: this.ids,
          },
        }).then((res) => {
          console.log(res, "国美副联");
          if (res.code == 1) {
            this.guomei = res.data.list;
          } else {
            this.dialog = false;
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      }
    },
    cancel() {
      this.dialog = false;
      if (this.dialog == false) {
        document.title = "深圳書畫藝術學院";
      }
    },
    ticke() {
      //改变title
      if (this.form.print == 1 && this.ticket != "") {
        document.title = this.ticket;
      }
    },
    // 姓名排序
    fn2(a, b) {
      return a.name.localeCompare(b.name);
    },
    //排序
    abc(i) {
      console.log(i);
      this.tableData = [];
      this.arr = [];
      this.arr.push({
        title: i.prop,
        order: i.order,
      });
      console.log(this.arr);
      this.getList();
    },
  },
};
</script>

<style scoped="scoped">
.AdmissionTicket {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* 查询部分 */
.form {
  padding-top: 34px;
  margin-bottom: 10px;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.form ::v-deep .el-input__inner {
  width: 276px;
  height: 37px;
}

.form ::v-deep .el-form-item__label {
  color: #000000;
}

.form .el-select {
  width: 276px;
  height: 37px;
}

.form .el-button {
  margin-top: 5px;
}

/* 按钮 */
::v-deep .el-icon-refresh {
  font-weight: bold !important;
  color: #ffffff;
}

/* 导出按钮 */
.derive {
  display: inline-block;
  width: 113px;
  height: 30px;
  padding-left: 10px;
  vertical-align: middle;
  background: url(../../assets/export.png) 6px 6px no-repeat #18bc9c;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

/* 打印 */
.print {
  display: inline-block;
  width: 80px;
  height: 30px;
  vertical-align: middle;
  color: #ffffff;
  border-radius: 3px;
  border: none;
  background: #ff7300;
  margin-left: 18px;
  cursor: pointer;
}

/* 下拉选择 */
.but::v-deep .el-select .el-input__inner {
  width: 137px;
  height: 31px;
  vertical-align: middle;
  line-height: 31px;
}

.but ::v-deep .el-input__icon {
  height: 31px;
  line-height: 31px;
}

/* 本场名单按钮 */
.field {
  display: inline-block;
  height: 30px;
  width: 100px;
  vertical-align: middle;
  background-color: #29726c;
  color: #fff;
  margin-left: 20px;
  border-radius: 3px;
  border: none;
  cursor: pointer;
}

/* 表格 */
.el-table {
  margin: 20px;
}

::v-deep .el-table__cell {
  color: #000;
  padding: 0;
  height: 30px;
}

.txtbox {
  height: 19px;
  display: inline-block;
  margin-bottom: 18px;
  margin-right: 5px;
  font-size: 14px;
  color: #000000;
}

.itp {
  /* border: none; */
  height: 28px;
  outline: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #ccc;
}

/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}

/* 打印弹出样式 */
.AdmissionTicket ::v-deep .el-dialog__header {
  padding: 30px 0;
  /* padding: 0px 15px; */
  /* padding: 0px 30px; */
}

/* .AdmissionTicket ::v-deep .el-dialog__body {
  padding: 0px 15px;
} */

.AdmissionTicket ::v-deep .el-dialog__footer {
  padding: 10px 20px 20px !important;
  text-align: center;
}

/* 准考证 */
#printAdmission {
  width: 880px;
  /* height: 500px; */
  /* border: 1px #ccc solid; */
  margin: 0px auto;
  color: #333;
}

#printAdmission > div {
  margin-bottom: 200px;
}

#printAdmission .barcode {
  text-align: center;
}

#printAdmission .barcode img {
  width: 65%;
  height: 48px;
}

#printAdmission .barcode .codenum {
  width: 66%;
  margin: 0 auto;
  /* margin: 0 10%; */
  display: flex;
  justify-content: space-between;
}

/* 准考证表格 */
.table_one {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.table_one td {
  padding-left: 10px;
}

.table_one td {
  height: 30px;
}

.table_one .td_1 {
  width: 110px;
  height: 170px;
}

/* 照片 */
.table_one .td_1 img {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px dashed #ccc; */
  width: 100px;
  height: 140px;
  object-fit: cover;
}

.table_one .td_2 {
  height: 35px;
  font-size: 16px;
  /* font-weight: 600; */
  letter-spacing: 5px;
  text-align: center;
}

.table_one .td_3 {
  text-align: center;
  width: 100px;
}

.table_one .td_4 {
  width: 100px;
}

.table_one .td_5 {
  text-align: center;
}

.table_one .td_6 {
  width: 150px;
}

.table_one .td_7 {
  width: 80px;
}

.table_one .td_8 {
  width: 340px;
  font-size: 14px;
}

/* 考生须知 */
.bottom {
  width: 100%;
  margin-top: 5px;
  padding-left: 5px;
  position: relative;
}

.bottom .notice p {
  margin-top: 2px;
  font-size: 15px;
}
/* 
    .bottom .notice {
        position: relative;
    } */

/* 印章定位 */
.seal {
  position: absolute;
  right: 150px;
  top: 4px;
}

.sealNo {
  display: none;
}

/* 删除线 */
.xian {
  margin: 10px 0px 10px 0px;
  border-bottom: 2px dashed #333;
}

.xian1 {
  margin: 28px 0px;
  border-bottom: 2px dashed #333;
}
/* 副联表 */
.table_two {
  margin-top: 0px;
  width: 100%;
  border-collapse: collapse;
}

.table_two td {
  height: 30px;
  padding-left: 10px;
}

.table_two .td_1 {
  width: 100px;
  height: 130px;
}

.table_two .td_1 img {
  vertical-align: middle;
  /* border: 1px dashed #ccc; */
  width: 90px;
  height: 110px;

  object-fit: fill;
}

.table_two .td_2 {
  text-align: center;
  height: 35px;
  font-weight: bold;
  letter-spacing: 5px;
  font-size: 16px;
}

.table_two .td_2 span {
  float: right;
  padding-top: 5px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 14px;
}

.dialog-footer {
  margin-top: 20px;
  text-align: center;
}

.table_two .td_10 {
  width: 120px;
}

/* 二维码 */
.QRcode {
  width: 100px;
  height: 110px;
  padding: 0px !important;
  text-align: center;
}

.guo {
  padding: 5px 0 !important;
}

.QRcode img {
  vertical-align: middle;
  width: 75px;
  height: 75px;
  /* width: 95px;
  height: 95px; */
}

/* 点名表 */
#printRollcall {
  width: 100%;
}

.rollcall {
  width: 880px;
  color: #000;
}

/* 标题 */
.rollcall h2 {
  text-align: center;
  color: #333;
}

/* 头部内容 */
.rollcall .top {
  padding: 0px 10px;
  margin-top: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.rollcall .top .top_2 {
  color: blue;
}

.rollcall .center {
  padding: 10px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.rollcall .below {
  font-size: 16px;
  padding-left: 10px;
}

/* 表格 */
.rollcall .el-table {
  width: 850px !important;
  margin: 0px auto;
  margin-top: 10px;
  padding: 0px;
}

.rollcall ::v-deep .el-table__cell {
  height: 35px;
  padding: 0px !important;
}

.rollcall ::v-deep .el-table th.el-table__cell > .cell {
  padding: 0px !important;
}

.rollcall ::v-deep .el-table .cell {
  padding: 0px !important;
}

.rollcall ::v-deep .el-table--border,
.el-table--group {
  border: 1px solid #000000;
  border-color: #000;
}

.rollcall:nth-child(1) {
  margin-bottom: 60px;
}

/* 数据 */
.record {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;

  margin-bottom: 20px !important;
}

.record span {
  font-weight: bold;
}

.record input {
  outline: none;
  border: none;
  border-bottom: 1px solid #333;
  background: #fff;
  width: 100px;
  color: #333;
  padding-left: 5px;
}

/* 副联表 */
#printAssociate {
  width: 100%;
  color: #333;
}

.table_three {
  /* margin-top: 15px; */
  /* width: 100%; */
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
}

.table_three td {
  height: 35px;
  padding-left: 10px;
}

.table_three .td_1 {
  width: 100px;
  height: 130px;
}

.table_three .td_1 img {
  /* border: 1px dashed #ccc; */
  width: 90px;
  height: 120px;
}

.table_three .td_2 {
  text-align: center;
  height: 40px;
  font-weight: bold;
  letter-spacing: 5px;
  font-size: 16px;
}

.table_three .td_2 span {
  float: right;
  padding-top: 5px;
  padding-right: 20px;
  font-weight: 500;
  font-size: 14px;
}
</style>
